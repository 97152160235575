import {AxiosError, AxiosResponse} from "axios";
import axios from "axios";

export function parseResponse<T>(pendingResponse: Promise<AxiosResponse<T>>, checkSchema: (x: any) => T): Promise<T> {
  return pendingResponse
    .then(resp => checkSchema(resp.data))
    .catch(error => {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        switch (axiosError.response?.status) {
          case 400:
            throw new Error(`BadRequest: ${JSON.stringify(axiosError.response?.data)}`);
          default:
            const message = `An error has occurred: ${axiosError.response?.status}`;
            throw new Error(message);
        }
      } else if (error instanceof Error) {
        throw error;
      } else {
        throw new Error(`An error has occurred: ${JSON.stringify(error)}`);
      }
    });
}

export function DecodeBase64String<T>(base64string: string): T {
  return JSON.parse(Buffer.from(base64string, "base64").toString("utf-8"));
}

export function EncodeToBase64<T>(obj: T): string {
  return Buffer.from(JSON.stringify(obj), "utf-8").toString("base64");
}