import { useEffect, useState } from "react";
import {StandardTemplate, PageHead, Table, Button, CopyIcon, Alert, Spinner, Card, PageState, Tooltip} from '@myob/myob-widgets'
import {ApiKeyDetail, mkApiKeyDetailList, mkApiKeyDetail, ApiKeyUsage} from "./Models/ApiKeyDetail";
import service from "./service";
import {parseResponse} from "./utils";
import {now, formatDateString} from './effects'
import {Link, useLocation} from "react-router-dom";
import {descend, sort, propOr, compose, toLower, identity, take, isNil} from "ramda";

type AppStatus =
      ["Deleted"|"Copied"|"Created", string?]
    | ["Clear"]
    | ["Error", Error]
    | ["Loading"]
const ApplicationList = () => {
  const location = useLocation();
  const [apps, setApps] = useState<ApiKeyDetail[]>([]);
  const [appStatus, setAppStatus] = useState<AppStatus>(["Clear"])
  const [sortDirection, setSortDirection] = useState("unsorted");
  const [sortName, setSortName] = useState("");
  const [usage, setUsage] = useState<ApiKeyUsage>({})
  async function fetchApp() {
    try {
      setAppStatus(["Loading"])
      const apps = await parseResponse<ApiKeyDetail[]>(
        service.get("/api-key"),
        mkApiKeyDetailList
      );
      setAppStatus(["Clear"])
      setApps(appsSortedBy("createdAt", apps));
    } catch (error) {
        setAppStatus(["Error", error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`)])
    }
  }

  async function copySecret(app: ApiKeyDetail) {
    try {
      const apiKeyDetails = await parseResponse<ApiKeyDetail>(
        service.get(`/api-key/${app.id}`),
        mkApiKeyDetail
      );
      if (apiKeyDetails.clientSecret) {
        await navigator.clipboard.writeText(apiKeyDetails.clientSecret);
        setAppStatus(["Copied", `client secret of ${app.appName}`])
      } else {
        setAppStatus(["Error",new Error(`no client secret retrieved for app ${app.id}`)]);
      }
    } catch (error) {
      setAppStatus(["Error", new Error(`unknown error ${error}`)]);
    }
  }

  const mkAlert = (appStatus: AppStatus) => {
    switch (appStatus[0]) {
      case "Copied": return <Alert
          onDismiss={() => setAppStatus(["Clear"])}
          dismissAfter={3000}
          type="success"
      >
        <strong>Copied {appStatus[1]}!</strong>
      </Alert>
      case "Created": return <Alert
          onDismiss={() => setAppStatus(["Clear"])}
          dismissAfter={3000}
          type="success"
      >
        <strong>Created {appStatus[1]}!</strong>
      </Alert>
      case "Deleted": return <Alert
          onDismiss={() => setAppStatus(["Clear"])}
          dismissAfter={3000}
          type="success"
      >
        <strong>Delete {appStatus[1]}!</strong>
      </Alert>
      case "Error":
        return <Alert
            onDismiss={() => setAppStatus(["Clear"])}
            dismissAfter={5000}
            type="danger"
        >
          <strong>{appStatus[1].message}!</strong>
        </Alert>
      default: return <></>
    }
  }

  const byField = (field: string) => descend(compose(toLower, propOr("", field)));

  const appsSortedBy = (field: string, appsToSort: ApiKeyDetail[]) => sort(byField(field), appsToSort);

  const applySort = (field: string, nextSortDirection: string) => {
    setSortDirection(nextSortDirection);
    setSortName(field);
    const sortedApps = appsSortedBy(field, apps);
    sortDirection === "descending"
      ? setApps(sortedApps)
      : setApps(sortedApps.reverse());
  }

  const formatDate = (date: string | undefined): string =>  {
    return date ? formatDateString(date) : "long ago";
  }
  

  useEffect(() => {
    fetchApp().then(()=> {
      const createdSuccess = location.state?.created
      if(createdSuccess) setAppStatus(['Created', createdSuccess])
    });
  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

  async function retrieveUsage(id: string) {
    let currentTime = now()
    let currentMonth = take(7)((currentTime).toISOString())
    try {
      const u = await parseResponse<number[]>(service.get(`/api-key/${id}/report/month/${currentMonth}`), identity)
      const last24hUsage = u[(currentTime).getDate() -1] ?? 0
      setUsage({...usage, [id]: last24hUsage})
    } catch (error) {
      setAppStatus(["Error", error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`)])
    }
  }

  async function copy(value: string, name: string) {
      await navigator.clipboard.writeText(value);
      setAppStatus(["Copied", name])
  }

  switch (appStatus[0]) {
    case "Loading":
      return <Spinner size="medium"/>
    default:
      return <StandardTemplate
        pageHead={<PageHead title="Applications"/>}
        alert={mkAlert(appStatus)}
        tableHeader={<Table>
          <Table.Header>
            <Table.HeaderItem
                width="flex-2"
              columnName="Name"
              onSort={applySort}
              sortName="appName"
              sortDirection={"appName" === sortName ? sortDirection : "unsorted"}
            >
              Name
            </Table.HeaderItem>
            <Table.HeaderItem columnName="Client Id" width="flex-3">Key</Table.HeaderItem>
            <Table.HeaderItem columnName="Client Secret" width="flex-1">Secret</Table.HeaderItem>
            <Table.HeaderItem columnName="Usage" width="flex-1">Usage <Tooltip>API requests count starting from 00:00 UTC time to now</Tooltip></Table.HeaderItem>
            <Table.HeaderItem columnName="Usage Plan" width="flex-0">Plan</Table.HeaderItem>
            <Table.HeaderItem
                width="flex-1"
              columnName="Created At"
              onSort={applySort}
              sortName="createdAt"
              sortDirection={"createdAt" === sortName ? sortDirection : "unsorted"}
            >
              Created At
            </Table.HeaderItem>
            <Table.HeaderItem width="auto"></Table.HeaderItem>
          </Table.Header>
        </Table>}
      >
        <Table>
          <Table.Body>
            {apps.length === 0 ? <Card>
              <PageState
                title="You don't have any application yet!"
                actions={[<Link to="/register">Register a new application</Link>]}
                image={<img
                  src="https://feelix-assets.s3-ap-southeast-2.amazonaws.com/page-state/empty-state/no-results-found.svg"
                  alt="no results found"/>}
              >
              </PageState>
            </Card> : apps.map(row => (

              <Table.Row key={row.id} isInactive={!isNil(row.deactivatedAt)}>
                <Table.RowItem columnName="Name" className={`goto-detail goto-detail-${row.id}`} width="flex-2"><Link to={ `/application/${row.id}` }>{row.appName}</Link></Table.RowItem>
                <Table.RowItem columnName="Client Id" width="flex-3">
                  <code>{row.id}</code>
                  <Button
                      name={`Copy ${row.keyId} id`}
                      onClick={() => copy(row.id, `key ${row.appName}`)}
                      type="clear"
                      size="xs"
                      icon={<CopyIcon/>}
                  />
                </Table.RowItem>
                <Table.RowItem columnName="Client Secret" width="flex-1">{"********"}<Button
                    name={`Copy ${row.keyId} secret`}
                    onClick={() => copySecret(row)}
                    type="clear"
                    size="xs"
                    icon={<CopyIcon/>}
                /></Table.RowItem>

                <Table.RowItem columnName="Usage" width="flex-1">{
                  isNil(usage[row.id])
                      ? <Button type="link" onClick={async ()=>retrieveUsage(row.id)}>Retrieve Usage</Button>
                      : <span>{usage[row.id]}</span>
                }
                </Table.RowItem>
                <Table.RowItem columnName="Usage Plan" width="auto">{row.usagePlan}</Table.RowItem>
                <Table.RowItem columnName="Created At" width="flex-1">{formatDate(row.createdAt)}</Table.RowItem>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </StandardTemplate>
  }
}

export default ApplicationList;
