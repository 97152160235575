import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if(!window.location.hash.startsWith("#/")) {
    window.location.hash = window.location.hash.replace('#', '#/#')
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);