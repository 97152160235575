import {
  Navigation,
  MYOBLogo,
  AddIcon,
  SignOutIcon,
  SettingsIcon,
  GlossaryIcon,
} from "@myob/myob-widgets";

const brand = (
  <Navigation.Brand url="#/" width="73px">
    <MYOBLogo />
  </Navigation.Brand>
);

const primary = [
  <Navigation.Link
    key="application"
    label="Applications"
    url="#/application"
  />,
  <Navigation.Link
    key="register"
    label="Register"
    url="#/register"
    icon={<AddIcon />}
  />,
];

const SettingsMenuItems = [
  <Navigation.MenuLink
    key="Documentation"
    label="Documentation"
    url="https://developer.myob.com/api/"
    icon={<GlossaryIcon />}
  />,
  <Navigation.MenuLink
    key="Logout"
    label="Logout"
    url="/logout"
    icon={<SignOutIcon />}
  />,
];

const settingsMenu = [
  <Navigation.Menu
    key="settingsMenu"
    icon={<SettingsIcon aria-label="settingsMenu" />}
    items={SettingsMenuItems}
  />,
];

export const Nav = () => (
  <Navigation fluid brand={brand} primary={primary} settings={settingsMenu} />
);
