import "@myob/myob-styles/dist/styles/myob-clean.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ApplicationList from "./ApplicationList";
import Home from "./Home";
import Register from "./Register";
import {Nav} from "./Nav";
import ApplicationDetail from "./ApplicationDetail";
import STSApplicationList from "./STSApplicationList";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Nav/>
          <Routes>
            <Route  path="/application" element={<ApplicationList />} />
            <Route  path="/import" element={<STSApplicationList />} />
            <Route  path="/register" element={<Register />} />
            <Route  path="/application/:clientId" element={<ApplicationDetail />} />
            <Route  path="/*" element={<Home />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
