import { useEffect, useState } from "react";
import {StandardTemplate, PageHead, Table, ArrowRightCircleIcon, Alert, Spinner, Card, PageState} from '@myob/myob-widgets'
import {ApiKeyDetail, mkApiKeyDetailList} from "./Models/ApiKeyDetail";
import service from "./service";
import {parseResponse} from "./utils";
import {Link, useLocation} from "react-router-dom";
import {isNil} from "ramda";

type AppStatus =["Clear"]
    | ["Error", Error]
    | ["Loading"]
const ApplicationList = () => {
  const location = useLocation();
  const [apps, setApps] = useState<ApiKeyDetail[]>([]);
  const [appStatus, setAppStatus] = useState<AppStatus>(["Clear"])
  async function fetchApp() {
    try {
      setAppStatus(["Loading"])
      const apps = await parseResponse<ApiKeyDetail[]>(
        service.get("/sts-api-key"),
        mkApiKeyDetailList
      );
      setAppStatus(["Clear"])
      setApps(apps);
    } catch (error) {
        setAppStatus(["Error", error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`)])
    }
  }

  const mkAlert = (appStatus: AppStatus) => {
    switch (appStatus[0]) {
      case "Error":
        return <Alert
            onDismiss={() => setAppStatus(["Clear"])}
            dismissAfter={5000}
            type="danger"
        >
          <strong>{appStatus[1].message}!</strong>
        </Alert>
      default: return <></>
    }
  }


  useEffect(() => {
    fetchApp()
  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

  switch (appStatus[0]) {
    case "Loading":
      return <Spinner size="medium"/>
    default:
      return <StandardTemplate
        pageHead={<PageHead title="Applications"/>}
        alert={mkAlert(appStatus)}
        tableHeader={<Table>
          <Table.Header>
            <Table.HeaderItem
              columnName="Name"
            >
              Name
            </Table.HeaderItem>
            <Table.HeaderItem columnName="Client Id" >Key</Table.HeaderItem>
            <Table.HeaderItem columnName="Usage Plan">Plan</Table.HeaderItem>
            <Table.HeaderItem >Import</Table.HeaderItem>
          </Table.Header>
        </Table>}
      >
        <Table>
          <Table.Body>
            {apps.length === 0 ? <Card>
              <PageState
                title="You don't have any application yet!"
                actions={[<Link to="/register">Register a new application</Link>]}
                image={<img
                  src="https://feelix-assets.s3-ap-southeast-2.amazonaws.com/page-state/empty-state/no-results-found.svg"
                  alt="no results found"/>}
              >
              </PageState>
            </Card> : apps.map(row => (

              <Table.Row key={row.id} isInactive={!isNil(row.deactivatedAt)}>
                <Table.RowItem columnName="Name" className={`goto-detail goto-detail-${row.id}`} >{row.appName}</Table.RowItem>
                <Table.RowItem columnName="Client Id" >
                  <code>{row.id}</code>
                </Table.RowItem>
                <Table.RowItem columnName="Usage Plan">{row.usagePlan}</Table.RowItem>
                <Table.RowItem><Link to="/register" state={{fromSTS: row}}><ArrowRightCircleIcon/></Link></Table.RowItem>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </StandardTemplate>
  }
}

export default ApplicationList;
