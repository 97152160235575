import {JsonDecodeError} from "../JsonDecodeError";

export type ApiKeyDetail = {
  id: string;
  keyId?: string;
  usagePlan?: string;
  appName: string;
  description?: string;
  userName?: string;
  appLogo?: string;
  websiteUrl?: string;
  redirectUri: string[];
  clientSecret?: string;
  createdAt?: string;
  updatedAt?: string;
  deactivatedAt?: string;
};

export type ApiKeyUsage = Record<string, number>

export function mkApiKeyDetail(input: any): ApiKeyDetail {
  if (input.id){
    return input as ApiKeyDetail;
  }
  throw new JsonDecodeError(`Cannot create ApiKeyDetail from ${JSON.stringify(input)}`);
}

export function mkApiKeyDetailList(input: any): ApiKeyDetail[] {
  if (Array.isArray(input)){
    return input.map(mkApiKeyDetail);
  }
  throw new JsonDecodeError(`Cannot create ApiKeyDetail List from ${JSON.stringify(input)}`)
}