import {
  PageHead,
  StandardTemplate,
} from "@myob/myob-widgets";
import { equals, last, path, propOr } from "ramda";
import {Navigate, useLocation, Location} from "react-router";
import { DecodeBase64String } from "./utils";

const extractRedirect = (state: string): string | undefined => {
  try {
    const loginStateJSON = JSON.parse(
      localStorage.getItem("login.state") || "{}"
    );
    return equals(
      propOr("no nonce in token", "nonce", getPayloadFromToken()),
      propOr("no nonce in state", "nonce", loginStateJSON)
    )
      ? path([state, "redirect"], loginStateJSON)
      : undefined;
  } catch (e) {
    console.error("extractRedirect has error", e);
    return undefined;
  }
};

const getPayloadFromToken = (): string | undefined => {
  const payload = getIdToken();
  return payload
    ? DecodeBase64String(propOr("{}", "1", payload.split(".")))
    : undefined;
};

const getIdToken = (): string | undefined => {
  const idToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id_token="));
  return idToken ? last(idToken.split("=")) : undefined;
};

const redirectByState = (location: Location): string | undefined => {
  const stateInHash = location.hash?.match(/state=(\d+)/);
  const state = last(stateInHash || []);
  return state ? extractRedirect(state) : undefined;
};

const Home = () => {
  const location: Location = useLocation();
  const redirect = redirectByState(location);
  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <StandardTemplate
      pageHead={<PageHead title="Developer Dashboard" />}
    >
      <h3>Hey Developer</h3>

      <p>
        Great to see you have got up and running already. Just a reminder if you need some help or some documentation? Sure,
        we can help with that. Head over to our brand new <a href="https://developer.myob.com/">Developer Portal</a> and check out the <a href="https://developer.myob.com/docs/read/Getting_Started">Getting Started documentation</a>.
      </p>

      <p>
        You can also find us on <a href="mailto:developers@myob.com">email</a>, <a href="https://www.twitter.com/myobapi">twitter</a> and our <a href="https://myobapi.tumblr.com/">blog</a> - along with our <a href="https://developer.myob.com/docs/read/resources/Code_Samples">code samples</a> which can help you get up and running fast.
      </p>
    </StandardTemplate>
  );
};

export default Home;
