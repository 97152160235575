import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const genState = (redirect: string) => {
  const random = window.crypto.getRandomValues(new Uint32Array(2));
  const nonce = random[0].toString();
  const state = random[1].toString();

  localStorage.setItem(
    "login.state",
    JSON.stringify({
      [state]: { redirect },
      nonce: nonce,
    })
  );
  return { state, nonce };
};

const Page401 = () => {
  const location = useLocation();

  useEffect(() => {
    const { state, nonce } = genState(location.pathname);
    window.location.assign(`/login?state=${state}&nonce=${nonce}`);
  }, );

  return <p>Loading...</p>;
};

export default Page401;
