import {ChangeEvent, useState} from "react";
import { ApplicationForm } from "./Models/AppilcationDetails";

const useForm = () => {
  const [inputs, setInputs] = useState<ApplicationForm>({
    name: "",
    redirectUri: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const [formSuccess, setFormSuccess] = useState(false);

  return {
    handleInputChange,
    setInputs,
    inputs,
    formSuccess,
    setFormSuccess,
  };
};

export default useForm;
