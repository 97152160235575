import { equals, identity, includes, isNil, join, split} from 'ramda'
import {useNavigate, useParams} from "react-router";
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonRow,
  Card,
  FieldGroup,
  FormTemplate,
  Input,
  Label, Modal,
  PageHead,
  Spinner,
  StandardTemplate,
  TextArea
} from "@myob/myob-widgets";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {parseResponse} from "./utils";
import {ApiKeyDetail, mkApiKeyDetail} from "./Models/ApiKeyDetail";
import service from "./service";
import {formatDateString} from "./effects";


type PageStatus = ["Error", Error?] | ["Loading"] | ["Loaded"] | ["Dirty"] | ["Updating"] | ["Updated"] | ["Deleting"]


function disableWhenLoading(pageStatus: PageStatus) {
  return includes(pageStatus[0], ['Error', "Loading", "Updating", "Deleting"]);
}

function dirtyCheck(updated: ApiKeyDetail, app: ApiKeyDetail | undefined) {
  return app ? !equals(JSON.stringify(updated), JSON.stringify(app)) : false;
}

function cleanUpEmptyURIFields(app: ApiKeyDetail): ApiKeyDetail {
  if (!app.appLogo) app.appLogo = undefined
  if (!app.websiteUrl) app.websiteUrl = undefined
  return app
}

const ApplicationDetail = () => {
  const {clientId} = useParams();
  const [pageStatus, setPageStatus] = useState<PageStatus>(["Loading"])
  const [app, setApp] = useState<ApiKeyDetail>();
  const [local, setLocal] = useState<ApiKeyDetail>();
  const [showDeleteApp, setShowDeleteApp] = useState<ApiKeyDetail|undefined>()

  async function fetchApp() {
    try {
      const app = await parseResponse<ApiKeyDetail>(
        service.get(`/api-key/${clientId}`),
        mkApiKeyDetail
      );
      setApp(app);
      setLocal(app);
      setPageStatus(["Loaded"])
    } catch (error) {
      setPageStatus(["Error", error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`)])
    }
  }

  const mkAlert = () => {
    switch (pageStatus[0]) {
      case "Updated":
        return <Alert
          onDismiss={() => setPageStatus(["Loaded"])}
          dismissAfter={3000}
          type="success"
        >
          <strong>App {app?.appName} updated successfully.</strong>
        </Alert>
      case "Error":
        return <Alert
          onDismiss={() => {
            setLocal(app)
            setPageStatus(["Loaded"])
          }}
          dismissAfter={5000}
          type="danger"
        >
          <strong>{pageStatus[1]?.message}!</strong>
        </Alert>
      default:
        return <></>
    }
  }

  const showDeleteModal = (props?: ApiKeyDetail) => props && <Modal modalId={`delete-modal-${props.id}`} size="small" title={`Delete this application`} onCancel={() => setShowDeleteApp(undefined)}>
    <Modal.Body>
      <p>
        Do you wish to <b>delete</b> this application <b>{props.appName}</b>?
      </p>
      <p>By deleting this application your customers will no longer have access to this application.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setShowDeleteApp(undefined)} type="secondary">Cancel</Button>
      <Button className="double-confirm" onClick={async () => deleteApp(props)} type="delete">Yes, delete this application</Button>
    </Modal.Footer>
  </Modal>

  async function deleteApp(app: ApiKeyDetail) {
    try {
      await service.delete(`/api-key/${app.id}`)
      setPageStatus(["Deleting"])
      navigate(-1)
    } catch (error) {
      setPageStatus(["Error", new Error(`Error when deleting application ${app.appName}`)])
    } finally {
      setShowDeleteApp(undefined)
    }
  }
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const updated = event.target.name === 'redirectUri' ?
      { ...local, redirectUri: split(',', event.target.value)} as ApiKeyDetail:
      {...local, [event.target.name]: event.target.value} as ApiKeyDetail;
    setLocal(updated);
    setPageStatus(dirtyCheck(updated, app) ? ['Dirty'] : ['Loaded'])
  };

  const form = (app: ApiKeyDetail) => (
    <>
      <FieldGroup>
        <Input
          value={clientId}
          label="Key"
          name="id"
          disabled={true}
        />
        <Input
          disabled={disableWhenLoading(pageStatus)}
          onChange={handleInputChange}
          value={app.appName}
          label="Name"
          name="appName"
          placeholder="Application name"
          requiredLabel="This field is required"
        />
        <Input
          disabled={disableWhenLoading(pageStatus)}
          onChange={handleInputChange}
          value={join(',', app.redirectUri)}
          label="Redirect Uri"
          name="redirectUri"
          placeholder="https://example.com/callback"
          requiredLabel="This field is required"
          type="url"
        />
        <Input
          disabled={disableWhenLoading(pageStatus)}
          onChange={handleInputChange}
          value={app.websiteUrl}
          label="Website Address"
          name="websiteUrl"
          placeholder="https://example.com"
          type="url"
        />
        <Input
          disabled={disableWhenLoading(pageStatus)}
          onChange={handleInputChange}
          value={app.appLogo}
          label="App logo"
          name="appLogo"
          placeholder="https://example.com/app.png"
          type="url"
        />
        <TextArea
          disabled={disableWhenLoading(pageStatus)}
          onChange={handleInputChange}
          value={app.description}
          label="Description"
          name="description"
          placeholder="Description"
          resize="none"
        />
        <Input
          value={app.updatedAt ? formatDateString(app.updatedAt) : 'long ago'}
          label="Last updated"
          name="updatedAt"
          disabled={true}
        />
        <Input
          value={app.createdAt ? formatDateString(app.createdAt) : 'long ago'}
          label="Date created"
          name="createdAt"
          disabled={true}
        />
      </FieldGroup>
    </>
  );

  const EditApplication = (app: ApiKeyDetail) => (
    <form onSubmit={submit(app)}>
      <FormTemplate
        actions={pageFooter}
      >
        <Card
          body={<Card.Body child={form(app)}/>}
        />
      </FormTemplate>
    </form>
  );

  const save = async (app: ApiKeyDetail) => {
    setPageStatus(["Updating"])
    return parseResponse<string>(
      service.put(`/api-key/${app.id}`, cleanUpEmptyURIFields(app)),
      identity
    ).catch(error => {
      setPageStatus(["Error", error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`)])
    });
  };

  const submit = (app: ApiKeyDetail) => async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    const apiKeyDetail = await save(app);
    if (apiKeyDetail) {
      await fetchApp()
      setPageStatus(["Updated"])
    }
  };
  const navigate = useNavigate()
  const pageFooter = (
    <ButtonRow>
      <Button
        type="secondary"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Button
        className="initial-delete"
        type="delete"
        onClick={() => setShowDeleteApp(app)}
      >
        Delete
      </Button>
      <Button
        HTMLType="submit"
        disabled={!(local?.appName && local?.redirectUri) || pageStatus[0] !== 'Dirty'}
        type="primary"
      >
        Save changes
      </Button>
    </ButtonRow>
  );

  useEffect(() => {
    setPageStatus(["Loading"])
    fetchApp()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return isNil(local) ?
    <Spinner size="medium"/> : isNil(app) ? <>Error in loading the app {clientId}</> :
      <>
        {pageStatus[0] === 'Updating' ?
          <Spinner size="medium"/> : <></>}
        <StandardTemplate
          pageHead={<PageHead title="Edit an existing application"/>}
          sticky="all"
          alert={mkAlert()}
          wcagAA={true}
        >
          <PageHead title="Application details"/>
          {showDeleteModal(showDeleteApp)}
          <Box display="flex" justifyContent="center" marginTop="sm" height="20sm">
            <Box justifyContent="left">
              <Avatar type='business' name={app.appName} imageSource={app.appLogo} color="light-grey"/>
            </Box>
            <Box justifyContent="right"
              paddingLeft="sm">
              <Box>
                <Label className="app-name" size="large">{app.appName}</Label>
              </Box>
              <Box>
                <Label size="small">Regular web app</Label>
              </Box>
            </Box>
          </Box>
          {EditApplication(local)}
        </StandardTemplate></>

}

export default ApplicationDetail;