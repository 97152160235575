import {pipe, map, split, trim, equals, reject} from 'ramda'

export type ApplicationForm = {
    stsClientId?: string;
    name: string;
    redirectUri: string;
    websiteUrl?: string;
    appLogo?: string;
    description?: string;
};

export type ApplicationDetailReq = {
    name: string;
    redirectUri: string[];
    websiteUrl?: string;
    appLogo?: string;
    description?: string;
}

export function mkApplicationDetailReq(form: ApplicationForm): ApplicationDetailReq {
    const toArray = pipe(split(','), map(trim));
    return {
        ...reject(equals(''), form),
        name: form.name,
        redirectUri: toArray(form.redirectUri),
    }
}