import {Alert, Button, ButtonRow, Card, FieldGroup, FormTemplate, Input, PageHead, TextArea,} from "@myob/myob-widgets";
import service from "./service";
import {parseResponse} from "./utils";
import {ApplicationForm, mkApplicationDetailReq} from "./Models/AppilcationDetails";
import {ApiKeyDetail, mkApiKeyDetail} from "./Models/ApiKeyDetail";
import {useLocation, useNavigate} from "react-router-dom";
import {FormEvent, useEffect, useState} from "react";
import useForm from "./useForm";
import Page401 from "./Page401";

const Register = () => {

  const location = useLocation();

  const handlePost = async (applicationToAdd: ApplicationForm) => {
    return parseResponse<ApiKeyDetail>(
        service.post("/api-key", mkApplicationDetailReq(applicationToAdd)),
        mkApiKeyDetail
    ).catch(error => {
      setError(error instanceof Error ? error : new Error(`Error ${JSON.stringify(error)}`))
    });
  };

  const submit = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    const apiKeyDetail = await handlePost(inputs);
    if (apiKeyDetail) {
      setFormSuccess(true);
    }
  };

  const {
    inputs,
    handleInputChange,
    setInputs,
    formSuccess,
    setFormSuccess,
  } = useForm();
  const [error, setError] = useState<Error>();

  const pageFooter = (
    <ButtonRow>
      <Button
        HTMLType="submit"
        disabled={!(inputs.name && inputs.redirectUri)}
      >
        Register Application
      </Button>
    </ButtonRow>
  );

  const form = (
    <>
      <FieldGroup label="Application details">
        <Input
          onChange={handleInputChange}
          value={inputs.name}
          label="Name"
          name="name"
          placeholder="My Application"
          requiredLabel="This field is required"
        />
        <Input
          onChange={handleInputChange}
          label="Redirect Uri"
          name="redirectUri"
          placeholder="https://example.com/callback"
          requiredLabel="This field is required"
          type="url"
        />
        <Input
          onChange={handleInputChange}
          label="Website Address"
          name="websiteUrl"
          placeholder="https://example.com"
          type="url"
        />
        <Input
          onChange={handleInputChange}
          label="App logo"
          name="appLogo"
          placeholder="https://example.com/app.png"
          type="url"
        />
        <TextArea
          onChange={handleInputChange}
          label="Description"
          name="description"
          placeholder="Description"
          resize="none"
          value={inputs.description || ""}
        />
      </FieldGroup>
    </>
  );

  const navigate = useNavigate();
  useEffect(()=> {
    if(formSuccess) navigate('/application', {state : {created: inputs.name}})

  }, [formSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(location.state?.fromSTS) {
      setInputs({
        stsClientId: location.state.fromSTS.id,
        name: location.state.fromSTS.appName,
        redirectUri: location.state.fromSTS.redirectUri,
        description: location.state.fromSTS.description,
        websiteUrl: location.state.fromSTS.websiteUrl,
      })
    }
  }, [location.state]) // eslint-disable-line react-hooks/exhaustive-deps
  const cookieExists = document.cookie.indexOf('id_token') === -1;
  if (cookieExists) {
    return <Page401 />;
  }

    return (
      <form onSubmit={submit}>
        <FormTemplate
          actions={pageFooter}
          pageHead={<PageHead title="Register an Application" />}
          alert={error instanceof Error ? <Alert
            type="danger">{error.message}</Alert> : null}
        >
          <Card
            body={<Card.Body child={form} />}
          />
        </FormTemplate>
      </form>
    );
};

export default Register;
