import axios, { AxiosError } from "axios";

const service = axios.create({
  baseURL: "/v1",
  timeout: 30000,
  xsrfCookieName: "id_token",
  xsrfHeaderName: "Authorization",
});

const genState = () => {
  const random = window.crypto.getRandomValues(new Uint32Array(2));
  const nonce = random[0].toString();
  const state = random[1].toString();

  localStorage.setItem(
    "login.state",
    JSON.stringify({
      [state]: { redirect: "/application" },
      nonce: nonce,
    })
  );
  return { state, nonce };
};

service.interceptors.response.use((response) => {
  return response;
}, (error: AxiosError) => {
  if (error.response?.status === 401){
    const { state, nonce } = genState();
    window.location.assign(`/login?state=${state}&nonce=${nonce}`)
  }
  return Promise.reject(error);
});

export default service;
